  .merge-algo
  {
    justify-content: center;
align-items: center;
height: 200px;
background-color: #f5f5f5;
padding: 20px;
text-align: center;
border: 1px solid #ccc;
border-radius: 5px;
transition: all 0.3s ease-in-out;
height: 111px;

  }
  .second-para
  {
    justify-content: center;
align-items: center;
height: 200px;
background-color: #f5f5f5;
padding: 20px;
text-align: center;
border: 1px solid #ccc;
border-radius: 5px;
transition: all 0.3s ease-in-out;
height: 111px;

  }
  @media (max-width: 500px) {
      .merge-algo{
        width: 80%;
        margin-bottom: 20px;
        height: 340px;
      }
    .mergealgo .merge-algo {
        margin-bottom: 20px;
       
        height: 342px;
      }
      .mergealgo .second-para
      { width: 80%;
        height: 100px;
      }
      img{
        width: 80%
      }
      .array{
        width: 80%;
      }
    
    
     .mergealgo .quick-algo
      {
        height: 150px;
      }
      .quick-algo1
      {
        height:200px ;
      }
      .container
      {
        justify-content: space-between;
        padding-left: 20px;
      }
  }
  
 
  